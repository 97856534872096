import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import styles from './_error.module.scss';
import { useLocaleContext } from '../components/shared/Locale';
import { ROOT_PATH } from '../configs';
import LocaleImage from '../components/shared/LocaleImage';
import GeneralHead from '../components/shared/GeneralHead/GeneralHead';

export default function Custom404() {
  const { locale } = useLocaleContext();
  const { replace } = useRouter();

  useEffect(() => {
    Sentry.captureException(new Error(`Page 404: ${window.location.href}`));
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <GeneralHead />

      <div>
        <div className={styles.imageWrapper}>
          <LocaleImage src="api-issue.png" />
        </div>

        <h1>{locale('page_not_found')}</h1>

        <button
          type="button"
          className={styles.footerBtn}
          onClick={() => replace(ROOT_PATH)}
        >
          {locale('website_start_go_to_homepage')}
        </button>
      </div>
    </div>
  );
}
